<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent width="600">
            <v-card class="px-4">
                <v-card-title class="mb-4">
                    <span class="text-h4 font-weight-bold">创建课程包</span>
                </v-card-title>
                <!-- <v-row justify="center"> -->
                <v-row>
                    <v-col cols="12" md="5" offset="1">
                        <v-img
                            :src="previewImg"
                            height="220"
                            width="200"
                            class="grey darken-4"
                        ></v-img>
                    </v-col>
                    <v-col cols="12" md="5">
                        <form class="">
                            <v-text-field
                                label="课程包名称"
                                v-model="name"
                                required
                                :error-messages="nameErrors"
                                :counter="20"
                                @input="$v.name.$touch()"
                                @blur="$v.name.$touch()"
                            ></v-text-field>

                            <v-text-field
                                label="副标题"
                                v-model="desc"
                                required
                                :error-messages="descErrors"
                                :counter="20"
                                @input="$v.desc.$touch()"
                                @blur="$v.desc.$touch()"
                            ></v-text-field>

                            <v-file-input
                                v-model="file"
                                required
                                :rules="rules"
                                accept="image/png, image/jpeg, image/jpg"
                                label="课程包封面图片"
                                small-chips
                                @change="uploadFile"
                            ></v-file-input>
                        </form>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="grey"
                        text
                        @click="cancel()"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="green darken-1"
                        text
                        @click="submit()"
                    >
                        确认
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import qiniu from '@/utils/qiniu'
import { getImgUrl } from "@/utils/util"
import { deleteImage } from "@/api/admin";

export default {
    name: "EditTopicDlg",

    mixins: [validationMixin],
    validations: {
        name: { required, maxLength: maxLength(20) },
        desc: { required, maxLength: maxLength(20) },
    },

    data: function () {
        return {
            dialog: false,
            
            name: "",
            desc: "",
            cover: "",
            index: 0,
            previewImg: "",

            file: null,
            // isUploading: false,
            rules: [
                value => !value || value.size < 2000000 || '图片大小要求小于2MB',
            ],
        }
    },
    computed: {
        nameErrors() {
            const errors = []
            if (!this.$v.name.$dirty) return errors
            !this.$v.name.maxLength &&
                errors.push("不能超过" + this.$v.name.maxLength + "个字符")
            !this.$v.name.required && errors.push("必须填写课程包名称")
            return errors
        },
        descErrors() {
            const errors = []
            if (!this.$v.desc.$dirty) return errors
            !this.$v.name.maxLength &&
                errors.push("不能超过" + this.$v.desc.maxLength + "个字符")
            !this.$v.desc.required && errors.push("必须填写副标题")
            return errors
        },
    },
    methods: {
        openDlg(item = false) {
            this.clear()

            if (item) {
                this.name = item.name
                this.desc = item.desc
                this.cover = item.cover
                // this.index = item.index
                this.previewImg = getImgUrl(item.cover)

            }
            // 默认图片
            if (this.previewImg == "") {
                this.previewImg = require('@/assets/images/admin/default-cover.jpeg')
            }

            this.dialog = true
        },
        clear() {
            this.$v.$reset()
            
            this.name = ""
            this.desc = ""
            this.cover = ""
            this.index = 0

            this.previewImg = ""
            this.file = null
            // this.isUploading = false
        },
        cancel() {
            this.clear()
            this.dialog = false
        },
        submit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                // if (this.isUploading == false && this.cover != "") {
                // if (this.cover != "") {
                    // 用户确认后调用submit回调，将新的参数传递出去
                    // this.$emit("submit", this.name, this.desc, this.cover)
                    this.$emit("submit", {
                        name: this.name,
                        desc: this.desc,
                        cover: this.cover,
                        index: this.index,
                    })
                    this.dialog = false

                // } else {
                //     console.log("正在上传图片，请稍等")
                // }
            }
        },

        // 上传完成后回调：
        async uploadDoneCallback(newCover) {
            // 1.删除之前的图片
            if (this.cover) {
                await deleteImage(this.cover)
            }

            // 2.保存新图片id
            this.cover = newCover

            // 3.恢复上传
            // this.isUploading = false
        },
        // 用户选择图片后触发
        async uploadFile() {
            // console.log('got upload file')
            // console.log(this.file)
            if (!this.file) {
                // this.isUploading = false
                return
            }

            // this.isUploading = true

            if (this.file) {
                // 展示预览图
                this.previewImg = URL.createObjectURL(this.file)

                // 上传到七牛
                await qiniu.uploadFile(this.file, 'images', this.uploadDoneCallback)
            }
        },
    },
    components: {},
};
</script>
