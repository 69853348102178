<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent width="560">
            <v-card class="px-4">
                <!-- <v-card-title class="mb-4 ml-7"> -->
                <v-card-title class="mb-4">
                    <!-- <span class="text-h3 font-weight-bold grey--text text--darken-2">增加课程</span> -->
                    <span class="text-h4 font-weight-bold">增加课程</span>
                </v-card-title>
                <!-- <v-row justify="center"> -->
                <!-- <v-row> -->
                <div class="content">
                    <!-- <v-col cols="12" md="5" offset="1"> -->
                    <div class="left">
                        <!-- <v-img
                            :src="previewImg"
                            height="220"
                            width="200"
                            class="grey darken-4"
                        ></v-img> -->
                        <v-img
                            :src="previewImg"
                            height="150"
                            width="150"
                        ></v-img>
                    </div>
                    <!-- <v-col cols="12" md="5"> -->
                    <div class="right">
                        <form class="">
                            <v-text-field
                                label="课程名称"
                                v-model="name"
                                required
                                :error-messages="nameErrors"
                                :counter="20"
                                @input="$v.name.$touch()"
                                @blur="$v.name.$touch()"
                            ></v-text-field>

                            <v-radio-group row v-model="courseType">
                                <v-radio key="1" label="Scratch" value="SCRATCH" ></v-radio>
                                <v-radio key="2" label="Python" value="PYTHON" ></v-radio>
                                <v-radio key="3" label="机器人" value="ROBOT" ></v-radio>
                            </v-radio-group>

                            <v-text-field
                                label="课程说明"
                                v-model="desc"
                                required
                                :error-messages="descErrors"
                                :counter="30"
                                @input="$v.desc.$touch()"
                                @blur="$v.desc.$touch()"
                            ></v-text-field>

                            <v-text-field
                                label="知识点"
                                v-model="points"
                                required
                                :error-messages="pointsErrors"
                                @input="$v.points.$touch()"
                                @blur="$v.points.$touch()"
                            ></v-text-field>
                            
                            <v-select
                                v-model="level"
                                :items="levels"
                                :error-messages="levelErrors"
                                label="难度"
                                required
                                @change="$v.level.$touch()"
                                @blur="$v.level.$touch()"
                            ></v-select>

                            <v-file-input
                                v-model="file"
                                required
                                :rules="rules"
                                accept="image/png, image/jpeg, image/jpg"
                                label="课程封面图片"
                                small-chips
                                @change="uploadFile"
                            ></v-file-input>
                        </form>
                    </div>
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="grey"
                        text
                        @click="cancel()"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="green darken-1"
                        text
                        @click="submit()"
                    >
                        确认
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import qiniu from '@/utils/qiniu'
import { getImgUrl } from "@/utils/util"
import { deleteImage } from "@/api/admin";

export default {
    name: "EditCourseDlg",

    mixins: [validationMixin],
    validations: {
        name: { required, maxLength: maxLength(20) },
        courseType: { required },
        desc: { required },
        points: { required },
        level: { required },
    },

    data() {
        return {
            dialog: false,
            file: null,
            // levels: ["1", "2", "3", "4", "5", "6"],
            levels: [1, 2, 3, 4, 5, 6],

            id: "",
            name: "",
            courseType: "",
            desc: "",
            points: "",
            level: 0,
            cover: "",
            index: 0,

            previewImg: "",

            rules: [
                value => !value || value.size < 2000000 || '图片大小要求小于2MB',
            ],
        };
    },
    computed: {
        nameErrors() {
            const errors = [];
            if (!this.$v.name.$dirty) return errors;
            !this.$v.name.maxLength &&
                errors.push("Name must be at most 10 characters long");
            !this.$v.name.required && errors.push("需要填写课程名称");
            return errors;
        },
        descErrors() {
            const errors = [];
            if (!this.$v.desc.$dirty) return errors;
            !this.$v.desc.required && errors.push("需要填写内容介绍");
            return errors;
        },
        pointsErrors() {
            const errors = [];
            if (!this.$v.points.$dirty) return errors;
            !this.$v.points.required && errors.push("需要填写知识点");
            return errors;
        },
        levelErrors() {
            const errors = [];
            if (!this.$v.level.$dirty) return errors;
            !this.$v.level.required && errors.push("需要设定课程难度");
            return errors;
        },
    },
    created() {},
    activated() {},
    watch: {},
    methods: {
        openDlg(course = null, groupId = "") {
            if (course) {
                this.id = course.cid
                this.name = course.name
                this.courseType = course.course_type
                this.desc = course.desc
                this.points = course.points
                // this.level = course.level.toString()
                this.level = course.level
                this.cover = course.cover
                this.index = course.index
                this.previewImg = getImgUrl(course.cover)
                this.file = null
            } else {
                this.clear();
            }

            // 默认图片
            if (this.previewImg == "") {
                this.previewImg = require('@/assets/images/admin/default-group.png')
            }

            this.groupId = groupId
            this.dialog = true
        },
        clear() {
            this.$v.$reset()

            this.id = ""
            this.name = ""
            this.courseType = ""
            this.desc = ""
            this.points = ""
            this.level = 0
            this.cover = ""
            this.index = 0
            this.groupId = ""
            this.previewImg = ""
            this.file = null
        },
        cancel() {
            this.clear()
            this.dialog = false
        },
        submit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                // if (this.isUploading == false && this.cover != "") {
                // if (this.cover != "") {
                    // 用户确认后调用submit回调，将新的参数传递出去
                    this.$emit("submit", {
                        cid: this.id,
                        name: this.name,
                        courseType: this.courseType,
                        desc: this.desc,
                        points: this.points,
                        level: this.level,
                        cover: this.cover,
                        index: this.index,
                        groupId: this.groupId
                    })
                    this.dialog = false

                // } else {
                //     console.log("正在上传图片，请稍等")
                // }
            }
        },

        // 上传完成后回调：
        uploadDoneCallback(newCover) {
            // 1.删除之前的图片
            if (this.cover) {
                deleteImage(this.cover).then((response) => {
                    console.log(response.msg)

                }).catch(function (err) {
                    console.log(err);
                });
            }

            // 2.保存新图片id
            this.cover = newCover

            // 3.恢复上传
            // this.isUploading = false
        },
        // 用户选择图片后触发
        async uploadFile() {
            // console.log('got upload file')
            // console.log(this.file)
            if (!this.file) {
                // this.isUploading = false
                return
            }

            // this.isUploading = true

            if (this.file) {
                // 展示预览图
                this.previewImg = URL.createObjectURL(this.file)

                // 上传到七牛
                await qiniu.uploadFile(this.file, 'images', this.uploadDoneCallback)
            }
        },
    },
    components: {},
};
</script>

<style lang="scss" scoped>
.content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .left {
        margin-right: 40px;
        margin-top: 50px;
    }
    .right {
        display: flex;
    }
}
</style>
