<template>
    <v-container fluid class="px-8 mb-8">
        <!-- 课程包基本内容和状态 -->
        <v-card class="course-info mb-12" elevation="2">
            <v-img
                :src="getImgUrl(topic.cover, defaultImg4Topic)"
                height="125"
                width="200"
                max-width="200"
                class="grey darken-4 me-5"
            ></v-img>

            <div class="course-desc">
                <div class="desc-col">
                    <div class="mb-2 desc-row">
                        <div class="font-weight-medium">
                            课程包名称：
                        </div>
                        <div class="text">{{ topic.name }}</div>
                    </div>
                    <div class="mb-2 desc-row">
                        <div class="font-weight-medium">
                            课程包说明：
                        </div>
                        <div class="text">{{ getText(topic.desc, "暂无说明") }}</div>
                    </div>
                </div>
            </div>
            <div class="operate" v-if="topic.is_private">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-2"
                            icon
                            height="42"
                            width="42"
                            outlined
                            elevation="2"
                            small
                            color="purple"
                            @click.stop="openEditTopicDlg(topic)"
                        >
                            <v-icon dark> mdi-pencil </v-icon>
                        </v-btn>
                    </template>
                    <span>编辑课程包</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-2"
                            icon
                            height="42"
                            width="42"
                            outlined
                            elevation="2"
                            small
                            color="blue darken-2"
                            @click.stop="openConfirmDlg('duplicateTopic')"
                        >
                            <v-icon dark> mdi-content-copy </v-icon>
                        </v-btn>
                    </template>
                    <span>克隆课程包</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-2"
                            icon
                            height="42"
                            width="42"
                            outlined
                            elevation="2"
                            small
                            color="red darken-1"
                            @click.stop="openConfirmDlg('delTopic')"
                        >
                            <v-icon dark> mdi-trash-can </v-icon>
                        </v-btn>
                    </template>
                    <span>删除课程包</span>
                </v-tooltip>
            </div>
            <div class="operate" v-else >
                <v-btn
                    tile
                    dark
                    color="blue darken-2"
                    class="font-weight-bold text-body-1"
                    @click.stop="openConfirmDlg('duplicateTopic')"
                >
                    <v-icon left> mdi-plus-circle-outline </v-icon>
                    克隆课程包
                </v-btn>
            </div>
        </v-card>

        <!-- 增加分组按钮 -->
        <div class="add-btn-row" v-if="topic.is_private">
            <div>
                <v-btn
                    tile
                    dark
                    color="blue darken-2"
                    class="font-weight-bold text-body-1"
                    @click.stop="openEditTopicGroupDlg()"
                >
                    <v-icon left> mdi-plus-circle-outline </v-icon>
                    增加分组
                </v-btn>
            </div>
            <div>
                <!-- <v-btn
                    tile
                    dark
                    color="blue darken-1"
                    class="font-weight-bold text-body-1 mr"
                    @click.stop="openConfirmDlg('onlineTopic')"
                >
                    <v-icon left> mdi-play </v-icon>
                    课程包上线
                </v-btn>
                <v-btn
                    tile
                    dark
                    color="deep-orange darken-1"
                    class="font-weight-bold text-body-1 mr-0"
                    @click.stop="openConfirmDlg('offlineTopic')"
                >
                    <v-icon left> mdi-play </v-icon>
                    课程包下线
                </v-btn> -->
            </div>
        </div>

        <!-- 分组列表 -->
        <draggable
            :disabled="disabledGroupList"
            v-model="groups"
            @change="groupListChange"
            v-if="groups.length > 0"
        >
            <v-card
                class="course-group-wrapper mb-5"
                elevation="2"
                v-for="group in groups"
                :key="group.group_id"
            >
                <!-- 课程分组信息：Cover、标题、说明 -->
                <div class="group-info-wrapper">
                    <div class="group-img">
                        <v-img
                            :src="getImgUrl(group.cover, defaultImg4Group)"
                            height="120"
                            width="120"
                            max-width="120"
                            class=""
                            contain
                        ></v-img>
                    </div>

                    <div class="group-info">
                        <div class="group-desc" @click.stop="toggleCourseList(group)">
                            <div class="mb-4 desc-row">
                                <div class="desc-row-name">标题：</div>
                                <div class="text-h5 font-weight-light">
                                    {{ group.name }}
                                </div>
                            </div>
                            <div class="desc-row">
                                <div class="desc-row-name">说明：</div>
                                <div class="text-h5 font-weight-light">
                                    {{ group.desc }}
                                </div>
                            </div>
                        </div>
                        <div class="btn-group">
                            <template v-if="topic.is_private">
                                <v-btn
                                    tile
                                    dark
                                    color="orange"
                                    class="font-weight-bold text-body-1 mr-6"
                                    @click.stop="openEditCourseDlg(null, group.group_id)"
                                >
                                    <v-icon left> mdi-plus-circle-outline </v-icon>
                                    新增课程
                                </v-btn>
                                <!-- <v-btn
                                    tile
                                    dark
                                    color="light-blue darken-1"
                                    class="font-weight-bold text-body-1 mr-0"
                                    @click.stop="openSelectCourseDlg(group)"
                                >
                                    <template>
                                        <v-icon left> mdi-table-search </v-icon>
                                        添加已有课程
                                    </template>
                                </v-btn> -->
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-bind="attrs" v-on="on"
                                            class="mx-1"
                                            icon
                                            height="42"
                                            width="42"
                                            outlined
                                            elevation="2"
                                            small
                                            color="purple"
                                            @click.stop="openEditTopicGroupDlg(group)"
                                        >
                                            <v-icon dark> mdi-pencil </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>编辑分组</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-bind="attrs" v-on="on"
                                            class="mx-2"
                                            icon
                                            height="42"
                                            width="42"
                                            outlined
                                            elevation="2"
                                            small
                                            color="red"
                                            @click.stop="openConfirmDlg('delGroup', { id: group.group_id })"
                                        >
                                            <v-icon dark color="red darken-1"> mdi-trash-can </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>删除分组</span>
                                </v-tooltip>
                            </template>

                            <v-btn
                                class="ml-4"
                                fab
                                outlined
                                x-small
                                color="grey lighten-1"
                                @click.stop="toggleCourseList(group)"
                            >
                                <v-icon dark size="24" color="grey lighten-1" v-if="group.isShow"> mdi-chevron-up </v-icon>
                                <v-icon dark size="24" color="grey lighten-1" v-else> mdi-chevron-down </v-icon>
                            </v-btn>
                        </div>
                    </div>
                </div>

                <!-- 分组课程列表 -->
                <div class="group-course-list" v-if="group.isShow">
                    <div class="course-list">
                        <div class="list-group-empty" v-if="!group.hasOwnProperty('courses') || group.courses.length <= 0">
                            暂无课程
                        </div>
                        <template v-else>
                        <div class="list-group-headers">
                            <div class="index">顺序</div>
                            <div class="index">序号</div>
                            <div class="type">类型</div>
                            <div class="name">课程名称</div>
                            <div class="desc">课程说明</div>
                            <div class="status">状态</div>
                            <div class="op" v-if="topic.is_private">操作</div>
                        </div>
                        <draggable
                            :list="group.courses"
                            :group="{ name: 'group', pull: 'clone', put: true }"
                            :disabled="!topic.is_private"
                            class="list-group"
                            ghost-class="ghost"
                            @change="courseListChange(group.group_id, $event)"
                            handle=".handle"
                        >
                            <div
                                class="list-group-item"
                                v-for="(item, index) in group.courses"
                                :key="item.cid"
                            >
                                <div class="group-item">
                                    <div><v-icon class="handle"> mdi-sort </v-icon></div>
                                    <div class="index">{{ index + 1 }}</div>
                                    <div class="type">
                                        <v-chip :color="getTypeColor(item.course_type)" small dark >
                                            {{ getTypeFirstLetter(item.course_type) }}
                                        </v-chip>
                                    </div>
                                    <div class="name">
                                        <div class="active-name"
                                            @click.stop="goto(item.cid, group.group_id)"
                                        >
                                            {{ item.name }}
                                        </div>
                                    </div>
                                    <div class="desc">
                                        {{ getText(item.desc, "暂无说明") }}
                                    </div>
                                    <div class="status">
                                        <v-chip
                                            label
                                            small
                                            class="font-weight-medium"
                                            :color="item.status == 1 ? 'light-green' : 'grey lighten-1'"
                                            text-color="white"
                                            @click="toggleIsOnline(item)"
                                        >
                                            {{ getStatus(item.status) }}
                                        </v-chip>
                                    </div>
                                    <div class="op" v-if="topic.is_private">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    v-bind="attrs" v-on="on"
                                                    class="mx-2"
                                                    icon
                                                    height="32"
                                                    width="32"
                                                    outlined
                                                    x-small
                                                    elevation="2"
                                                    color="purple"
                                                    @click.stop="openEditCourseDlg(item, group.group_id)"
                                                >
                                                    <v-icon dark> mdi-pencil </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>编辑课程</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    v-bind="attrs" v-on="on"
                                                    class="mx-2"
                                                    icon
                                                    height="32"
                                                    width="32"
                                                    outlined
                                                    x-small
                                                    elevation="2"
                                                    color="blue darken-2"
                                                    @click.stop="openConfirmDlg('duplicateCourse', { groupId: group.group_id, cid: item.cid })"
                                                >
                                                    <v-icon dark> mdi-content-copy </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>复制课程</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    v-bind="attrs" v-on="on"
                                                    class="mx-2"
                                                    icon
                                                    height="32"
                                                    width="32"
                                                    outlined
                                                    x-small
                                                    elevation="2"
                                                    color="green"
                                                    @click.stop="moveCourse({ groupId: group.group_id, cid: item.cid })"
                                                >
                                                    <v-icon dark> mdi-file-move-outline </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>移动课程</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    v-bind="attrs" v-on="on"
                                                    class="mx-2"
                                                    icon
                                                    height="32"
                                                    width="32"
                                                    outlined
                                                    x-small
                                                    elevation="2"
                                                    color="red darken-1"
                                                    @click.stop="openConfirmDlg('delCourse', { groupId: group.group_id, cid: item.cid })"
                                                >
                                                    <v-icon dark> mdi-trash-can </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>删除课程</span>
                                        </v-tooltip>
                                    </div>
                                </div>
                            </div>
                        </draggable>
                        </template>
                    </div>
                </div>
            </v-card>
        </draggable>
        <div class="empty-content" v-else>
            <v-img
                :src="img.emptyContent"
                max-width="50"
            ></v-img>
            <div class="text">暂无分组</div>
        </div>

        <EditTopicDlg ref="editTopicDlg" @submit="submitTopic" />
        <EditTopicGroupDlg ref="editTopicGroupDlg" @submit="submitGroup" />

        <EditCourseDlg ref="editCourseDlg" @submit="submitCourse" />

        <!-- 选择将课程移动到哪个课程包中 -->
        <MoveCourseToDlg ref="moveToDlg" @submit="submitMoveTo" />

        <ConfirmDlg
            ref="duplicateTopicConfirmDlg"
            title="克隆课程包"
            text="确认 克隆 当前课程包么？"
            keyword=" 克隆 "
            @confirm="duplicateTopicConfirm"
        />
        <ConfirmDlg
            ref="delTopicConfirmDlg"
            title="删除课程包"
            text="确认 删除 当前课程包内容么？"
            keyword=" 删除 "
            @confirm="delTopicConfirm"
        />
        <ConfirmDlg
            ref="delTopicGroupConfirmDlg"
            title="删除分组"
            text="确认 删除 当前分组么？"
            keyword=" 删除 "
            @confirm="delTopicGroupConfirm"
        />

        <ConfirmDlg
            ref="onlineConfirmDlg"
            title="课程包上线"
            text="确认 上线 当前课程包内容么？"
            keyword=" 上线 "
            @confirm="onlineConfirm"
        />
        <ConfirmDlg
            ref="offlineConfirmDlg"
            title="课程包下线"
            text="确认 下线 当前课程包内容么？"
            keyword=" 下线 "
            @confirm="offlineConfirm"
        />

        <ConfirmDlg
            ref="duplicateCourseConfirmDlg"
            title="复制课程"
            text="确认 复制 当前课程么？"
            keyword=" 复制 "
            @confirm="duplicateCourseConfirm"
        />
        <ConfirmDlg
            ref="delCourseConfirmDlg"
            title="删除课程"
            text="确认从分组中 删除 课程么？"
            keyword=" 删除 "
            @confirm="delCourseConfirm"
        />
    </v-container>
</template>

<script>
import draggable from "vuedraggable";
import EditTopicDlg from "@/views/components/dialog/EditTopicDlg";
import EditTopicGroupDlg from "@/views/components/dialog/EditTopicGroupDlg";
import EditCourseDlg from "@/views/components/dialog/EditCourseDlg";
import MoveCourseToDlg from "@/views/components/dialog/MoveCourseToDlg";
// import SelectCourseDlg from "@/views/components/dialog/SelectCourseDlg";
import ConfirmDlg from "@/views/components/dialog/ConfirmDlg";
import { goBack, getText, getImgUrl, getTypeFirstLetter } from "@/utils/util";
import { getTypeColor } from '@/utils/common';
import {
    getAdminTopic,
    getAdminGroupList,
    updateAdminGroupListIndex,
    getAdminGroupCourseRelations,
    updataAdminGroupCourseRelations,

    editAdminCourse,
    duplicateAdminCourse,
    moveAdminCourse,
    delAdminCourse,
    setAdminCourseOnline,

    editAdminTopic,
    duplicateAdminTopic,
    delAdminTopic,
    onlineTopic,
    offlineTopic,
    
    editAdminGroup,
    delAdminGroup,
} from "@/api/admin";

export default {
    name: "TopicGroup",

    data() {
        return {
            defaultImg4Topic: require("@/assets/images/admin/default-cover.jpeg"),
            defaultImg4Group: require("@/assets/images/admin/default-group.png"),
            img: {
                emptyContent: require("@/assets/images/admin/empty-content.svg"),
            },

            topic: {},
            groups: [
                // 结构说明：
                // {
                //     id: '',
                //     group_id: '',
                //     topic_id: '',
                //     name: '',
                //     desc: '',
                //     cover: '',
                //     index: 0,
                //     courses: [], // 每个分组中的课程列表
                // }
            ],
            disabledGroupList: true,
            disabledCourseList: true,
            dragging: false,

            fromPath: '',
        };
    },
    mounted() {
        // let topicId = ''
        // if (this.$route.query.id) {
        //     topicId = this.$route.query.id
        // }
        // this.loadData(topicId)
    },
    activated() {
        let topicId = ''
        if (this.$route.query.id) {
            topicId = this.$route.query.id
        }
        // if (this.fromPath == '/course-mgr/topic-list') {
            this.loadData(topicId)
        // }
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            // 通过 vm 访问组件实例,将值传入fromPath
            vm.fromPath = from.path;
        });
    },
    methods: {
        goBack,
        getText,
        getImgUrl,
        getTypeColor,
        getTypeFirstLetter,
        getStatus(status) {
            if (status == 0) {
                return "未发布"
            } else if (status == 1) {
                return "已发布"
            }
        },
        goto(id, groupId) {
            this.$router.push({
                path: "/course-mgr/topic-list/topic/course",
                query: { id: id, groupId: groupId },
            });
        },
        async loadData(topicId) {
            // 获取主题信息
            getAdminTopic(topicId).then((response) => {
                // console.log(response.data);
                this.topic = response.data;

            }).catch(function (err) {
                console.log(err);
            });

            // 获取主题分组列表
            let groups = await getAdminGroupList(topicId)
            this.groups = groups.data

            for (let i = 0; i < this.groups.length; i++) {
                let group = this.groups[i]
                let courses = await getAdminGroupCourseRelations(group.group_id)
                this.groups[i].courses = courses.data
                this.groups[i].isShow = false
            }
            if (this.groups.length >= 1) {
                this.groups[0].isShow = true
            }
            this.$forceUpdate()
        },

        

        // 编辑课程包
        openEditTopicDlg(topic = null) {
            this.$refs.editTopicDlg.openDlg(topic);
        },
        // 编辑分组
        openEditTopicGroupDlg(group = null) {
            this.$refs.editTopicGroupDlg.openDlg(group);
        },
        // 选择课程对话框
        // openSelectCourseDlg(group) {
        //     // let type = group.type
        //     let groupId = group.group_id
        //     let selected = []
        //     for (let i in this.groups) {
        //         if (this.groups[i].group_id == groupId) {
        //             selected = this.groups[i].courses
        //         }
        //     }
        //     this.$refs.selectCourseDlg.openDlg(groupId, selected);
        // },
        // 确认对话框
        openConfirmDlg(type, params = null) {
            switch (type) {
                case "onlineTopic":
                    this.$refs.onlineConfirmDlg.openDlg();
                    break;
                case "offlineTopic":
                    this.$refs.offlineConfirmDlg.openDlg();
                    break;
                case "duplicateTopic":
                    this.$refs.duplicateTopicConfirmDlg.openDlg(params);
                    break;
                case "delTopic":
                    this.$refs.delTopicConfirmDlg.openDlg(params);
                    break;
                case "delGroup":
                    this.$refs.delTopicGroupConfirmDlg.openDlg(params);
                    break;
                case "duplicateCourse":
                    this.$refs.duplicateCourseConfirmDlg.openDlg(params);
                    break;
                case "delCourse":
                    this.$refs.delCourseConfirmDlg.openDlg(params);
                    break;
            }
        },
        // 打开创建/编辑课程对话框
        openEditCourseDlg(course = null, groupId = "",) {
            this.$refs.editCourseDlg.openDlg(course, groupId);
        },
        // 表单对话框确认callback
        submitTopic(newTopic) {
            editAdminTopic({
                topicId: this.topic.topic_id,
                name: newTopic.name,
                desc: newTopic.desc,
                cover: newTopic.cover,
                index: newTopic.index,
            }).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    this.$store.dispatch("web/SetAlert", { type: "success", msg: "创建课程包成功"});

                    // 将主题更新到当前页面中
                    let result = response.data;
                    this.topic.name = result.name;
                    this.topic.desc = result.desc;
                    this.topic.cover = result.cover;
                } else {
                    this.$store.dispatch("web/SetAlert", { type: "error", msg: "创建课程包失败" });
                }
            }).catch(function (err) {
                console.log(err);
            });
        },

        // 添加分组
        submitGroup(newGroup) {
            editAdminGroup({
                topicId: this.topic.topic_id,
                id: newGroup.group_id,
                name: newGroup.name,
                desc: newGroup.desc,
                cover: newGroup.cover,
                index: newGroup.index,
            }).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    // 将新增分组添加到当前页面中
                    let result = response.data
                    if (newGroup.group_id == "") {
                        // 创建
                        this.groups.push(result)
                        // 新建后更新分组List的Index
                        this.updateAdminGroupListIndex()

                        this.$store.dispatch("web/SetAlert", { type: "success", msg: "创建分组成功" });

                    } else {
                        // 修改
                        for (let i in this.groups) {
                            if (this.groups[i].group_id == newGroup.group_id) {
                                this.groups[i].name = result.name
                                this.groups[i].type = result.type
                                this.groups[i].desc = result.desc
                                this.groups[i].cover = result.cover
                                break
                            }
                        }
                        this.$store.dispatch("web/SetAlert", { type: "success", msg: "更新分组成功" });
                    }
                } else {
                    this.$store.dispatch("web/SetAlert", {
                        type: "error",
                        msg: "创建分组失败",
                    });
                }
            }).catch(function (err) {
                console.log(err);
            });
        },
        // submitSelectCourse(result) {
        //     // console.log(result.selected)
        //     for (let i in this.groups) {
        //         if (this.groups[i].group_id == result.groupId) {
        //             // 合并选项
        //             // this.groups[i].courses 是当前分组中的课程列表
        //             // result.selected 是所有选中的课程列表
        //             let newList = this.getNewCourseList(this.groups[i].courses, result.selected)
        //             this.groups[i].courses = newList

        //             this.$forceUpdate()
        //             break
        //         }
        //     }
        //     this.updataAdminGroupCourseRelations(result.groupId)
        // },
        // 选择课程后，更新当前分组课程列表
        // getNewCourseList(curList, selected) {
        //     let newList = []

        //     // 更新selected标记，全部为新增
        //     for (let i = 0; i < selected.length; i++) {
        //         selected[i].isNew = true
        //     }

        //     // 先遍历当前课程列表
        //     // 如果课程在select中，push；更新selected标记
        //     // 如果课程不在select中，pass；
        //     for (let i = 0; i < curList.length; i++) {
        //         let pos = selected.findIndex(item => {
        //             return item.cid == curList[i].cid
        //         })
        //         if (pos >= 0) {
        //             newList.push(curList[i])
        //             selected[pos].isNew = false
        //         }
        //     }

        //     // 将selected标记为isNew（新增）的，push到newList末尾
        //     for (let i = 0; i < selected.length; i++) {
        //         if (selected[i].isNew) {
        //             // console.log(selected[i])
        //             newList.push(selected[i])
        //         }
        //     }
        //     return newList
        // },

        // 确认对话框confirm callback
        onlineConfirm() {
            // console.log("We got Online~");
            // console.log(this.topic.topic_id);

            onlineTopic(this.topic.topic_id).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    this.$store.dispatch("web/SetAlert", {
                        type: "success",
                        msg: "主题上线成功",
                    })

                    // 更新页面数据
                    this.topic.is_online = 1

                } else {
                    this.$store.dispatch("web/SetAlert", {
                        type: "error",
                        msg: "主题上线失败",
                    })
                }
            }).catch(function (err) {
                console.log(err)
            })
        },
        offlineConfirm() {
            // console.log("We got Offline~");
            console.log(this.topic.topic_id);
        },
        // 克隆整个课程包
        duplicateTopicConfirm() {
            duplicateAdminTopic(this.topic.topic_id).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    this.$store.dispatch("web/SetAlert", {
                        type: "success",
                        msg: "克隆课程包成功",
                    })
                } else {
                    this.$store.dispatch("web/SetAlert", {
                        type: "error",
                        msg: "克隆课程包失败",
                    })
                }
            }).catch(function (err) {
                console.log(err)
            })
        },
        // 删除课程包
        delTopicConfirm() {
            delAdminTopic(this.topic.topic_id).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    this.$store.dispatch("web/SetAlert", { type: "success", msg: "删除课程包成功" })

                    // 删除当前课程包成功，返回上一页
                    this.goBack()

                } else {
                    this.$store.dispatch("web/SetAlert", { type: "error", msg: "删除课程包失败" })
                }
            }).catch(function (err) {
                console.log(err)
            })
        },
        // 删除分组
        delTopicGroupConfirm(params) {
            delAdminGroup(params.id).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    let index = -1;
                    for (let i in this.groups) {
                        if (this.groups[i].group_id == params.id) {
                            index = i;
                            break;
                        }
                    }
                    if (index >= 0) {
                        this.groups.splice(index, 1);
                    }
                    this.$store.dispatch("web/SetAlert", { type: "success", msg: "删除分组成功" });

                } else {
                    this.$store.dispatch("web/SetAlert", { type: "error", msg: "删除分组失败" });
                }
            }).catch(function (err) {
                console.log(err);
            });
        },
        // 添加课程
        submitCourse(newCourse) {
            let groupId = newCourse.groupId

            editAdminCourse({
                cid: newCourse.cid,
                name: newCourse.name,
                courseType: newCourse.courseType,
                desc: newCourse.desc,
                points: newCourse.points,
                level: newCourse.level,
                cover: newCourse.cover,
                groupId: groupId
            }).then((response) => {
                if (response.msg == 'SUCCESS') {
                    this.$store.dispatch('web/SetAlert', {type: "success", msg: "创建课程成功"});

                    // 更新页面数据
                    let result = response.data

                    for (let i = 0; i < this.groups.length; i++) {
                        //找到对应分组
                        if (this.groups[i].group_id == groupId) {
                            if (!this.groups[i].courses) {
                                this.groups[i].courses = []
                            }
                            // 添加或更新课程信息
                            if (newCourse.cid == "") {
                                // 添加到列表结尾
                                this.groups[i].courses.push(result)
                            } else {
                                for (let j in this.groups[i].courses) {
                                    if (this.groups[i].courses[j].cid == newCourse.cid) {
                                        this.groups[i].courses[j] = result;
                                        break;
                                    }
                                }
                            }
                            // 将添加或修改了课程的分组展开
                            this.groups[i].isShow = true
                            break
                        }
                    }
                    this.$forceUpdate()

                } else {
                    this.$store.dispatch('web/SetAlert', {type: "error", msg: "创建课程失败"});
                }

            }).catch(function (err) {
                console.log(err);
            });
        },
        // 复制课程
        async duplicateCourseConfirm(params) {
            // console.log(params)
            let res = await duplicateAdminCourse({ groupId: params.groupId, cid: params.cid })
            if (res.msg == "SUCCESS") {
                for (let i in this.groups) {
                    //找到对应分组
                    if (this.groups[i].group_id == params.groupId) {
                        // 复制该分组中对应的课程
                        for (let j in this.groups[i].courses) {
                            if (this.groups[i].courses[j].cid == params.cid) {
                                this.groups[i].courses.splice(j + 1, 0, res.data)
                                break
                            }
                        }
                        break
                    }
                }
                this.$forceUpdate()
                this.$store.dispatch("web/SetAlert", { type: "success", msg: "复制课程成功" });

            } else {
                this.$store.dispatch("web/SetAlert", { type: "error", msg: "复制课程失败" });
            }
        },
        moveCourse(params) {
            this.$refs.moveToDlg.openDlg(params);
        },
        async submitMoveTo(params) {
            // console.log(params)
            let res = await moveAdminCourse({ cid: params.cid, groupId: params.groupId, newGroupId: params.newGroupId })
            if (res.msg == "SUCCESS") {

                for (let i in this.groups) {
                    //找到对应分组
                    if (this.groups[i].group_id == params.groupId) {
                        // 删除分组课程列表中的课程
                        let index = -1;
                        for (let j in this.groups[i].courses) {
                            if (this.groups[i].courses[j].cid == params.cid) {
                                index = j;
                                break;
                            }
                        }
                        if (index >= 0) {
                            this.groups[i].courses.splice(index, 1);
                        }
                        break
                    }
                }
                this.$forceUpdate()

                this.$store.dispatch("web/SetAlert", { type: "success", msg: "移动课程成功" });

            } else {
                this.$store.dispatch("web/SetAlert", { type: "error", msg: "移动课程失败" });
            }
        },

        async delCourseConfirm(params) {
            // console.log(params)
            await delAdminCourse({ groupId: params.groupId, cid: params.cid })

            for (let i in this.groups) {
                //找到对应分组
                if (this.groups[i].group_id == params.groupId) {
                    // 删除分组课程列表中的课程
                    let index = -1;
                    for (let j in this.groups[i].courses) {
                        if (this.groups[i].courses[j].cid == params.cid) {
                            index = j;
                            break;
                        }
                    }
                    if (index >= 0) {
                        this.groups[i].courses.splice(index, 1);
                    }
                    break
                }
            }

            // 说明：此处可以不更新index，因为index只管顺序，不一定需要连续
            // 下次添加、移动时就会同步更新数据库
            // this.updateCourseListIndex(id)
            this.$forceUpdate()

            this.$store.dispatch('web/SetAlert', {type: "success", msg: "删除成功"});
        },
        toggleCourseList(group) {
            // 如果当前列表已经展开，直接关闭
            // 如果当前列表关闭状态，先将其他分组列表关闭，再将其打开
            if (group.isShow) {
                group.isShow = false

            } else {
                for (let i = 0; i < this.groups.length; i++) {
                    this.groups[i].isShow = false
                }
                group.isShow = true
            }
            this.$forceUpdate()
        },
        


        // 分组列表调整顺序（draggable列表事件）
        groupListChange(e) {
            // console.log(e)
            this.updateAdminGroupListIndex()
        },
        // 课程列表调整顺序（draggable列表事件）
        courseListChange(groupId, e) {
            if (e.added) {
                // 说明：
                // 因为支持从其他分组列表中拖拽clone，
                // 所以添加时可能会有重复的课程，需要删掉
                let cid = e.added.element.cid
                let findCount = 0
                let findIndex = -1

                for (let i = 0; i < this.groups.length; i++) {
                    //找到对应分组
                    if (this.groups[i].group_id == groupId) {

                        // 找到分组中重复的课程cid，并删除
                        for (let j = 0; j < this.groups[i].courses.length; j++) {
                            if (this.groups[i].courses[j].cid == cid) {
                                findIndex = j
                                findCount++
                            }
                        }
                        if (findCount >= 2 && findIndex >= 0) {
                            this.groups[i].courses.splice(findIndex, 1);
                        }

                        break
                    }
                }
            }
            // if (e.removed) {
            //     console.log('removed')
            // }
            // if (e.moved) {
            //     console.log('moved')
            // }

            // 更新courses列表中各个元素的index
            // 看起来不需要了，直接保存数据库的时候更新就行
            // this.updateCourseListIndex(groupId)

            // 目前这个地方简化处理，就是直接把对应的分组删除，然后创建新的项
            this.updataAdminGroupCourseRelations(groupId)

            this.$forceUpdate()
        },
        clone(e) {
            // console.log(e)
        },
        async updateAdminGroupListIndex() {
            let groupIds = []
            for (let i = 0; i < this.groups.length; i++) {
                groupIds.push(this.groups[i].group_id)
            }
            
            await updateAdminGroupListIndex({
                id: this.topic.topic_id,
                groupIds: groupIds
            });
        },
        async updataAdminGroupCourseRelations(groupId) {
            for (let i = 0; i < this.groups.length; i++) {
                //找到对应分组
                if (this.groups[i].group_id == groupId) {
                    let courseIds = []

                    let courses = this.groups[i].courses
                    for (let j = 0; j < courses.length; j++) {
                        courseIds.push(courses[j].cid)
                    }

                    await updataAdminGroupCourseRelations({
                        id: groupId,
                        courseIds: courseIds
                    })
                    break
                }
            }
        },
        // 更新courses列表中各个元素的index
        updateCourseListIndex(groupId) {
            for (let i = 0; i < this.groups.length; i++) {
                //找到对于分组
                if (this.groups[i].group_id == groupId) {
                    for (let j = 0; j < this.groups[i].courses.length; j++) {
                        this.groups[i].courses[j].index = j
                    }
                    break
                }
            }
        },
        // 控制课程上线or下线
        toggleIsOnline(item) {
            console.log(item)
            if (item.status == 0) {
                item.status = 1
            } else {
                item.status = 0
            }

            this.$forceUpdate()

            setAdminCourseOnline({
                cid: item.cid,
                status: item.status
            })
        }
    },
    components: {
        draggable,
        EditTopicDlg,
        EditTopicGroupDlg,
        EditCourseDlg,
        MoveCourseToDlg,
        // SelectCourseDlg,
        ConfirmDlg,
    },
};
</script>

<style lang="scss" scoped>
.alert-area {
    position: absolute;
    width: 100%;
    z-index: 10;
    top: 0;
}
// .move-pointer {
//     cursor: move;
// }
.view-pointer {
    cursor: pointer;
}
// 去掉v-card默认的上下的margin
.v-card {
    margin: 0;
}

.add-btn-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.ghost {
    opacity: 0.5;
    background: #dddedf;
}

.course-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .course-desc {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .desc-col {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 0 20px;
        }
        .desc-row {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 35px;
            .desc-name {
                width: 70px;
                min-width: 70px;
            }
        }
        .text {
            text-align: left;
            font-weight: 300;
            font-size: 14px;
        }
    }
}

.operate {
    // height: 125px;
    height: 100%;
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.course-group-wrapper {
    display: flex;
    flex-direction: column;

    .group-info-wrapper {
        // flex: 4;
        display: flex;
        justify-content: space-between;
        // align-items: flex-start;
        align-items: center;
        position: relative;
        background-color: rgb(247, 247, 247);

        .group-img {
            // margin-right: 20px;
            padding: 20px;
            // background-color: #fff;
            // border-bottom: 1px solid #efefef;
            // &:hover {
            //     cursor: move;
            // }
        }

        .group-info {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .group-desc {
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                margin-left: 15px;
                min-height: 120px;
                &:hover {
                    // cursor: move;
                    cursor: pointer;
                }
                .desc-row {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    text-align: left;

                    .desc-row-name {
                        height: 28px;
                        width: 50px;
                        font-size: 16px;
                        font-weight: bold;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                    }
                }
            }

            .btn-group {
                // position: absolute;
                display: flex;
                align-items: center;
                // right: 12px;
                // bottom: 15px;
                height: 120px;
                margin-left: 8px;
                margin-right: 30px;
            }
        }
    }
    .group-course-list {
        flex: 6;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        // background-color: #f9f8f2;

        .course-list {
            flex: 1;
            // height: 125px;

            .list-group-empty {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                color: #999;
            }

            .list-group-headers {
                height: 48px;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #eee;
                font-size: 14px;
                font-weight: bold;
                color: #555;
                
                .index {
                    width: 60px;
                }
                .name {
                    flex: 1;
                    text-align: center;
                }
                .desc {
                    flex: 1;
                    text-align: left;
                }
                .status {
                    width: 100px;
                }
                .type {
                    width: 60px;
                }
                .op {
                    width: 225px;
                }
            }

            .list-group {
                height: 100%;
                align-items: flex-start;

                .list-group-item {
                    text-align: left;
                    height: 55px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid #eee;
                    .group-item {
                        display: flex;
                        align-items: center;
                        flex: 1;
                        // padding-left: 20px;
                        .handle {
                            width: 60px;
                            cursor: move;
                        }
                        .index {
                            // margin-right: 8px;
                            // color: #fff;
                            // font-weight: bold;
                            // background-color: #333;
                            width: 60px;
                            height: 22px;
                            border-radius: 25px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .name {
                            flex: 1;
                            font-size: 14px;
                            font-weight: bold;
                            text-align: center;
                            display: flex;
                            justify-content: center;
                            .active-name {
                                padding: 8px 25px;
                                border-radius: 50px;
                                // background-color: #efefef;
                                background-color: #fff;
                                &:hover {
                                    cursor: pointer;
                                    background-color: #fff;
                                    border-radius: 50px;
                                }
                            }
                        }
                        .desc {
                            flex: 1;
                            font-size: 14px;
                        }
                        .status {
                            width: 100px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .type {
                            width: 60px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .op {
                            width: 225px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                    .item-op {
                        width: 60px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    
                    &:hover {
                        // background-color: #ebebeb;
                        background-color: #F5F5F5;
                    }
                }
            }
        }
    }
}
.empty-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 80px;
    .text {
        color: #ccc;
        font-size: 14px;
        margin-top: 12px;
    }
}
</style>
