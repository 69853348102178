<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" width="850">
            <v-card class="px-4">
                <v-card-title class="mb-4">
                    <span class="text-h4 font-weight-bold">移动课程到</span>
                </v-card-title>
                <!-- <v-row justify="center"> -->
                <v-row>
                    <v-col cols="12" md="10" offset="1">
                        <div class="content">
                            <div class="content-col col-line">
                                <v-data-table
                                    v-model="selected"
                                    :headers="topicHeaders"
                                    :items="topics"
                                    item-key="topic_id"
                                    class="elevation-0 row-pointer"
                                    :single-select="singleSelect"
                                    :hide-default-footer="true"
                                    @click:row="selectTopic"
                                >
                                    <template v-slot:item.name="{ item }">
                                        <div
                                            class="font-weight-medium"
                                        >
                                            {{ item.name }}
                                        </div>
                                    </template>
                                </v-data-table>
                            </div>
                            <div class="content-col">
                                <v-data-table
                                    v-model="selected"
                                    :headers="groupHeaders"
                                    :items="groups"
                                    item-key="topic_id"
                                    class="elevation-0 row-pointer"
                                    :single-select="singleSelect"
                                    :hide-default-footer="true"
                                    @click:row="selectGroup"
                                >
                                    <template v-slot:item.name="{ item }">
                                        <div
                                            class="font-weight-medium"
                                        >
                                            {{ item.name }}
                                        </div>
                                    </template>
                                </v-data-table>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-spacer class="mb-4"></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { getAdminTopicList, getAdminGroupList } from "@/api/admin";
import { getTypeColor } from '@/utils/common';

export default {
    name: "MoveCourseToDlg",

    data: function () {
        return {
            dialog: false,

            topicHeaders: [
                { text: "课程包", value: "name", align: "center", sortable: false },
            ],
            topics: [],
            
            groupHeaders: [
                { text: "分组", value: "name", align: "center", sortable: false },
            ],
            groups: [],

            singleSelect: true,
            selected: [],
            
            params: "",
            newTopicId: "",
            newGroupId: "",
        };
    },
    computed: {},
    created() {},
    activated() {
    },
    mounted() {
    },
    watch: {},
    methods: {
        getTypeColor,
        loadData() {
            getAdminTopicList('PRIVATE').then((response) => {
                // console.log(response.data)
                this.topics = response.data
                
            }).catch(function (err) {
                console.log(err);
            });
        },
        openDlg(params) {
            this.params = params
           
            this.dialog = true

            this.loadData();
        },
        async selectTopic(row) {
            // console.log(row)
            this.newTopicId = row.topic_id

            // 获取主题分组列表
            let groups = await getAdminGroupList(row.topic_id)
            this.groups = groups.data
        },
        selectGroup(row) {
            // console.log(row)
            this.newGroupId = row.group_id

            this.$emit("submit", {
                cid: this.params.cid,
                groupId: this.params.groupId,
                newGroupId: this.newGroupId,
            })
            this.dialog = false
        }
    },
    components: {},
};
</script>

<style lang="scss" scoped>
.content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border: 1px solid #eee;
    .content-col {
        flex: 1;
        // border: 1px solid #eee;
        &.col-line {
            border-right: 1px solid #eee;
        }
    }
    .content-col-title {
        // border-bottom: 1px solid #eee;
        font-size: 15px;
        font-weight: bold;
        padding-bottom: 5px;
    }
}
</style>

<!-- 让v-data-table行中的cursor显示pointer -->
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
